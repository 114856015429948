import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactoGeneral from "../components/globals/contacto-general"

import BackgroundContacto from "../components/globals/background-contacto"

import "../scss/contacto-styles.scss"

const Contacto = () => (
  <Layout footer={false} menuactive="contacto">
    <SEO title="Contacto" />
    <BackgroundContacto />
    <ContactoGeneral />
  </Layout>
)

export default Contacto
