import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

const BackgroundContacto = () => (
  <StaticQuery query = { graphql `
      query {
        desktop: file(relativePath: { eq: "background-header-staffing.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    ` }
  render = {
    data => {
      const imageData = data.desktop.childImageSharp.fluid
      return ( <BackgroundImage Tag = "section"
            fluid = { imageData }
            className="image-background-container">
        </BackgroundImage>
      )
    }
  }
  />
)


export default BackgroundContacto