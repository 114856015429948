import React, { Component } from "react"
import * as emailjs from 'emailjs-com';
import { Link } from "gatsby"

class ContactoGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      active: false ,
      nombre : typeof window !== 'undefined' ? localStorage.getItem("nombre") : "",
      empresa : typeof window !== 'undefined' ? localStorage.getItem("empresa") : "",
      email : typeof window !== 'undefined' ? localStorage.getItem("email") : "",
      telefono : typeof window !== 'undefined' ? localStorage.getItem("telefono") : "",
      mensaje : typeof window !== 'undefined' ? localStorage.getItem("mensaje") : ""
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleSubmit = event => {
    event.preventDefault();
    emailjs.sendForm('gmail','template_jaN8FkEn', '#contactogeneral', 'user_kI39K1UNAGCmR7XJMmOaa')
    .then((response) => {
      this.setState({
          active: !this.state.active
        });
    }, (err) => {});
  }

  handleInputChange(event) {
    const target = event.target;
    const value =  target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

    localStorage.setItem(target.name, target.value);
  }

  handleClose = event => {
    this.setState({
      active: !this.state.active,
      nombre : "",
      empresa : "",
      email : "",
      telefono : "",
      mensaje : ""
    });
    localStorage.removeItem("nombre");
    localStorage.removeItem("empresa");
    localStorage.removeItem("email");
    localStorage.removeItem("telefono");
    localStorage.removeItem("mensaje");
  }

  handleClear = event => {
    this.setState({
      nombre : "",
      empresa : "",
      email : "",
      telefono : "",
      mensaje : ""
    });
    localStorage.removeItem("nombre");
    localStorage.removeItem("empresa");
    localStorage.removeItem("email");
    localStorage.removeItem("telefono");
    localStorage.removeItem("mensaje");
  }

  render() {
    return (
  <>
  <div className={this.state.active ? 'hidden' : "contacto-general" }>
    <h1>Contacto</h1>
    <form id="contactogeneral" onSubmit={this.handleSubmit}>
      <div className="form-control nombre">
        <input 
          type="text"
          name="nombre"
          id="nombre"
          placeholder="*Nombre(s) y Apellidos"
          value={this.state.nombre}
          onChange={this.handleInputChange}
          required></input>
      </div>
      <div className="form-control empresa">
        <input
          type="text"
          name="empresa"
          id="empresa"
          placeholder="Empresa"
          value={this.state.empresa}
          onChange={this.handleInputChange}></input>
      </div>
      <div className="form-control email">
        <input
          type="email"
          name="email"
          id="email"
          placeholder="*E-mail"
          value={this.state.email}
          onChange={this.handleInputChange}
          required></input>
      </div>
      <div className="form-control telefono">
        <input
          type="tel"
          name="telefono"
          id="telefono"
          placeholder="*Teléfono"
          value={this.state.telefono}
          onChange={this.handleInputChange}
          required></input>
      </div>
      <div className="form-control mensaje">
        <input
          type="text"
          name="mensaje"
          id="mensaje"
          placeholder="Mensaje"
          value={this.state.mensaje}
          onChange={this.handleInputChange}></input>
      </div>
      <div className="form-control">
        <span className="mensaje-privacidad">Al dar clic a "enviar" estás aceptando el <Link to="/aviso-de-privacidad">aviso de privacidad</Link></span>
      </div>
      <div className="form-button">
        <button className="btn-white" type="reset" onClick={this.handleClear}>Limpiar</button>
        <button type="submit">Enviar</button>
      </div>
    </form>
  </div>
  <div className={this.state.active ? "contacto-general-send-ok" : "hidden"}>
      <h1 className="bold">¡Gracias!</h1>
      <p className="text-white">
        Nos comunicaremos contigo<br />
        en breve.
      </p>
      <div className="form-button">
        <button type="button" onClick={this.handleClose} className="btn-white">Terminar</button>
      </div>
  </div>
  </>
  );
  }
}

export default ContactoGeneral